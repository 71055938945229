import React from "react";
import "./Education.scss";
import EducationCard from "../../components/educationCard/EducationCard";
import {educationInfo} from "../../portfolio";
import { Fade } from "react-reveal";


export default function Education() {
  if (educationInfo.display) {
    return (
        <div className="education-main-div" id="education">
          <Fade left duration={1000}>
            <div className="education-image-div">
              <img
                alt="man sitting on table"
                src={require("../../assets/images/manOnTable.svg")}
              ></img>
            </div>
          </Fade>
          <Fade right duration={1000}>
            <div className="education-text-div">
              <h1 className="education-heading">Education</h1>
              <div className="education-card-container">
                {educationInfo.schools.map((school, index) => (
                  <EducationCard key={index} school={school} />
                ))}
              </div>
            </div>
          </Fade>
        </div>
    );
  }
  return null;
}
