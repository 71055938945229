/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: false // Set to false to use static SVG
};

const greeting = {
  username: "Ian Mills",
  title: "Hi all, I'm Ian!",
  subTitle: emoji(
    "A passionate Technical Lead for my team. Experienced AR/VR Developer and Manager."
  ),
  resumeLink:
    "", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/Ian-Mills",
  linkedin: "https://www.linkedin.com/in/ianmillsdev/",
  gmail: "ian@ianmills.ie",
  medium: "https://medium.com/@ianmillsdev",
  twitter: "https://twitter.com/IanMillsDev",
  instagram: "https://www.instagram.com/i.m.mills/",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "I AM A TECHNICAL LEAD WHO WANTS TO CREATE THE FUTURE",
  skills: [
    emoji("⚡ Develop high performance AR/VR applications and experiences"),
    emoji(
      "⚡ Integration of cloud services such as Firebase/AWS"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "AR",
      fontAwesomeClassname: "fab fa-vr"
    },
    {
      skillName: "VR",
      fontAwesomeClassname: "fab fa-vr-cardboard"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "Unity",
      fontAwesomeClassname: "fab fa-unity"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "swift",
      fontAwesomeClassname: "fab fa-swift"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "c#",
      fontAwesomeClassname: "fab computer-classic"
    },
    {
      skillName: "c++",
      fontAwesomeClassname: "fab computer-classic"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "South East Technological University",
      logo: require("./assets/images/SETULogo.png"),
      subHeader: "MSc in Computer Science",
      duration: "September 2013 - May 2015",
      desc: "Participated in the research of 3D windowing systems.",
      descBullets: [
        "Dissertation: ",
        "Part-time while workng full time at TSSG/Walton"
      ]
    },
    {
      schoolName: "South East Technological University",
      logo: require("./assets/images/SETULogo.png"),
      subHeader: "BSc in Computer Science",
      duration: "September 2009 - May 2013",
      desc: "Software Engineering, Web Security, Operating Systems, ...",
      descBullets: ["Programming, 3D modelling, AI"]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Programming",
      progressPercentage: "90%"
    },
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "80%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    }
  ],
  displayCodersrank: true // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Technical Lead",
      company: "Walton Institute",
      companylogo: require("./assets/images/WaltonLogo.png"),
      date: "June 2013 – Present",
      desc: "Founded MRLab/Metaverse XRLab, Developed several projects I am currently working on a bleeding edge XR (eXtended Reality) technology based research team working on several projects ranging from VR, MR and AR applications, hardware and research.",
      descBullets: [
        "Software Development",
        "Project Management",
        "Project Finances",
        "SCRUM/Agile management"
      ]
    },
    {
      role: "Software Engineer Intern",
      company: "WIT/SETU",
      companylogo: require("./assets/images/SETULogo.png"),
      date: "Jan 2012 – Sep 2012",
      desc: "Enterprise Ireland IP Audibility \n Networking and Software engineer who worked on a mobile(Android) based application and backend system.",
      descBullets: [
        "Software Development",
        "Signal processing",
        "GPU processing",
        "Mobile development"
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  subtitle: "Currently udpating",
  projects: [
    {
      image: require("./assets/images/WaltonLogo.png"),
      projectName: "MRLab",
      projectDesc: "MRLab Founder",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://waltoninstitute.ie/about/staff/ian-mills"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/WaltonLogo.png"),
      projectName: "VRGlove/NerveVR",
      projectDesc: "Before the Mixed Relity Lab and the Metaverse Reality Lab there was VR Glove",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://waltoninstitute.ie/about/staff/ian-mills"
        }
        //  you can add extra buttons here.
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc
const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Technology Ireland Software Awards",
      subtitle:
        "Outstanding Academic Achievement of the Year 2017",
      image: require("./assets/images/WaltonLogo.png"),
      imageAlt: "Walton Logo",
      footerLink: [
        {
          name: "Website",
          url: "https://www.designpartners.com/projects/virtual-reality-smart-glove/"
        }
      ]
    },

    {
      title: "KTI Impact Awards 2020",
      subtitle: "Winner of People’s Choice Impact Award",
      image: require("./assets/images/WaltonLogo.png"),
      imageAlt: "Walton Logo",
      footerLink: [
        {name: "Website", 
        url: "https://www.wit.ie/news/research/tssg-at-wit-wins-at-the-kti-impact-awards-2020"}
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "I spend my free time developing cool stuff. I love to demosntrate and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://waltoninstitute.ie/news-and-events/news/equipment-applications-the-use-of-biosensing-in-ar-vr",
      title: "Equipment applications: The use of Biosensing in AR/VR",
      description:
        "In our latest blog post, AR/VR Technical Lead IanMillsDev describes some of the bio-sensing equipment available within the Mixed Reality Lab at Walton Institute, and its applications for health, sports, and marketing industries."
    },
    {
      url: "https://waltoninstitute.ie/news-and-events/news/equipment-applications-the-use-of-biosensing-in-ar-vr",
      title: "Equipment applications: The use of Biosensing in AR/VR",
      description:
        "In our latest blog post, AR/VR Technical Lead IanMillsDev describes some of the bio-sensing equipment available within the Mixed Reality Lab at Walton Institute, and its applications for health, sports, and marketing industries."
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "A lits of some of the events where I have  given talks."
  ),

  talks: [
    {
      title: "IEEE VR 2022",
      subtitle: "Presentation at IEEE VR 2022",
      slides_url: "https://youtu.be/8aUmRQV0QH8",
      event_url: "https://twitter.com/ianmillsdev/status/1503294493768003586"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  email_address: "ian@ianmills.ie"
};

// Twitter Section

const twitterDetails = {
  userName: "ianmillsdev", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
